import { prjList } from './prjList';

function Projects() {
	return (
		<section className="container-fluid px-0">
			<div className="row mx-0">
				<div className="col-12 prj-list px-0">
					{prjList.map((data, index) => {
						return (
						// <div className="prj-item" key={index}>
						<div className="prj-item" data-aos="fade-up" data-aos-delay={index + '50'} key={index}>
							<a href={data.url} target="_blank" rel="noreferrer">
								<span className='d-flex align-items-center justify-content-between'>
									{data.company} <svg xmlns="http://www.w3.org/2000/svg" className='ms-3' height="50" width="50" viewBox="0 0 32 32"><g fill="#ffffff"><path d="M31.71 15.29l-9-9-1.42 1.42 7.3 7.29H0v2h28.59l-7.29 7.29 1.41 1.41 9-9a1 1 0 0 0 0-1.41z" id="_117-Arrow_Right" data-name="117-Arrow Right"></path></g></svg>
								</span>


								<span className="ticker-wrap">
									<div className="ticker">
										<span className="item-collection-1">
											<span className="item">{data.company}</span>
											<span className="item">{data.company}</span>
											<span className="item">{data.company}</span>
											<span className="item">{data.company}</span>
										</span>
										<span className="item-collection-2">
											<span className="item">{data.company}</span>
											<span className="item">{data.company}</span>
											<span className="item">{data.company}</span>
											<span className="item">{data.company}</span>
										</span>
									</div>
								</span>
							</a>
						</div>
						);
					})}
				</div>
			</div>
		</section>
	)
}

export default Projects;