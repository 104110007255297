function Footer() {
	return (
		<footer className="container pt-5 mt-md-5 pb-3">
			<div className="row">
				<div className="col-12 text-center">
					<a href="mailto:hello@paolamaglia.it" rel="noreferrer">Contact.me</a> \ <a href="https://www.linkedin.com/in/paolamaglia/" target="_blank" rel="noreferrer">LinkedIN.me</a> \ <a href="https://paolamaglia.it/media/CV.pdf" target="_blank" rel="noreferrer">CV.me</a>
				</div>
			</div>
		</footer>
	)
}

export default Footer;