export const prjList = [
	{
		company: 'wndr.it',
		url: 'https://www.wndr.it/',
	},
	// {
	// 	company: 'Ottica Skandia',
	// 	url: 'https://www.otticaskandia.com/',
	// },
	{
		company: 'omeca.eu',
		url: 'https://www.omeca.eu/',
	},
	{
		company: 'pellegrini.net',
		url: 'https://www.pellegrini.net/',
	},
	{
		company: 'servizicec.it',
		url: 'https://www.servizicec.it/',
	},
	{
		company: 'siac-group.com',
		url: 'https://www.siac-group.com/',
	},
	{
		company: 'ginvitro.it',
		url: 'https://www.ginvitro.it/',
	},
	{
		company: 'diido.it',
		url: 'https://diido.it/',
	},
	{
		company: 'ilariab.com',
		url: 'https://www.ilariab.com/',
	},
	// {
	// 	company: 'BAB Legal',
	// 	url: 'https://bablegal.ch/',
	// },
	{
		company: 'wescansolutions.it',
		url: 'https://wescansolutions.it/',
	},
	// {
	// 	company: 'Raviolificio Poker',
	// 	url: 'https://raviolificiopoker.it/',
	// },
	// {
	// 	company: 'Milestone Med',
	// 	url: 'https://www.milestonemedsrl.com/it/',
	// },
	// {
	// 	company: 'Semplicemente Salute',
	// 	url: 'https://semplicementesalute.it/',
	// },
];