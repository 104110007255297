// import React from 'react';
import { Component } from 'react';
import anime from 'animejs';

// function handleClick() {
//     alert('You clicked me!')
// }


class Header extends Component {
	componentDidMount(){
		const tl = anime.timeline({
			easing: 'easeInOutSine',
			duration: 1500,
			delay: function(el, i) { return i * 250 },
		});
		tl
		.add({
			targets: '.logo-drawing .logo .el',
			strokeDashoffset: [anime.setDashoffset, 0],
			fill: '#ffffff',
		})
		.add({
			targets: '.star-drawing .star .el',
			strokeDashoffset: [anime.setDashoffset, 0],
			direction: 'alternate',
			loop: true
		});
	}



	render() {
		return (
			<header className="container">
				<div className="row">
					<div className="col-12 text-center mt-md-5 mt-4 px-4">
						<h1>Paola Maglia</h1>
						<div className="d-flex justify-content-center">
							<div className="logo-drawing">
								<svg width="100%" height="160.9499969482422" viewBox="0 0 434.55 160.95">
									<g id="logo" className="logo" transform="translate(-462.8 -437.7)" stroke="#fff" strokeWidth="1" fill="#FABD16">
										<path className="el" id="p" d="M28.05-40.2a107.986,107.986,0,0,1-2.7,24.9Q21.15,2.4,11.55,2.4,8.7,2.4,6.6-.6,4.2-4.35,3.3-4.65A136.743,136.743,0,0,0,1.125,11.475Q.45,20.1.45,30a72.306,72.306,0,0,0,.6,11.1A28.743,28.743,0,0,0,3,47.55q0,.6-2.1.6-5.1,0-5.1-17.85,0-7.2.9-17.625T-.75-11.7Q.9-25.8,1.65-35.625T2.25-51.3a2.161,2.161,0,0,1-.75-1.5q0-1.95,2.4-1.95,2.85,0,2.85,4.2A20.266,20.266,0,0,1,6.525-48,20.019,20.019,0,0,0,6.3-45.6v1.5a6.952,6.952,0,0,1,.15,1.35v7.05q1.5-4.5,4.05-10.65,3.75-8.55,5.85-8.55,6.9,0,9.6,4.05Q28.05-47.55,28.05-40.2Zm-9.6-11.4Q16.5-51.6,15-48l-3.3,8.7Q4.35-20.1,4.65-13.95q3,1.65,3,2.7a1.843,1.843,0,0,1-.675,1.125A1.893,1.893,0,0,0,6.3-8.85,9.441,9.441,0,0,0,7.65-3.9a4.626,4.626,0,0,0,4.2,2.7q5.25,0,8.7-12.45a63.611,63.611,0,0,0,2.7-16.95v-1.8a6.952,6.952,0,0,0,.15-1.35V-39.9Q23.4-51.6,18.45-51.6Z" transform="translate(467 540)"></path>
										<path className="el" id="a" d="M14.85-42q-.6-6.9-3.75-6.9-2.55,0-4.5,7.2Q6-40.05,5.25-37.35T3.45-31.2q-2.4,9.15-2.4,22.65A11.212,11.212,0,0,0,2.1-3.75Q3.45-.9,5.55-.9q2.85-1.65,6-11.55,1.2-3.9,2.1-7.275a41.771,41.771,0,0,0,1.2-6.075l1.8-13.8ZM24,0q-5.55,0-9.15-10.95Q10.95,3,4.8,3-4.05,3-3.6-18.9q.3-5.85,3.75-19.5Q4.5-54,7.5-54q6.6,0,8.4,3.45l2.7,5.4,5.25,1.8Q20.1-32.4,18.75-16.5a22.419,22.419,0,0,0,.6,7.2q1.2,5.25,3.75,5.25,5.1,0,13.95-36.9.9-3.45,2.1-3.45t1.2,2.25q0,5.55-5.55,22.65Q28.5,0,24,0Z" transform="translate(506 540)"></path>
										<path className="el" id="o" d="M42.3-43.05q0,6.6-3.9,15.6-3.45,7.95-9.15,7.95A27.354,27.354,0,0,1,24-21.3q-.3,1.65-.75,3.75T22.2-12.9Q21-9.45,20.025-7.05A24.734,24.734,0,0,1,18.3-3.45q-3,4.8-7.35,4.8Q0,1.35,0-21,0-35.25,2.4-40.2v.3a12.837,12.837,0,0,0-.15-1.35A12.4,12.4,0,0,1,2.1-42.9v-1.05a3.4,3.4,0,0,1,.9-2.1,13.715,13.715,0,0,0,.6-2.25q.3-1.5.9-4.05,1.05-5.1,5.1-5.1,3.9,0,8.1,3.3,4.35,3.3,5.55,6.75,1.2,3.6,1.2,21.75A6.378,6.378,0,0,0,28.2-24q5.25,0,7.8-5.85,1.2-2.25,3.15-11.85.6-2.7,1.8-2.7A1.193,1.193,0,0,1,42.3-43.05ZM19.65-20.85a33.554,33.554,0,0,0-.3-4.05q-.3-2.4-.9-5.55t-.9-5.475a31.78,31.78,0,0,1-.3-3.975q0-1.05.225-3.3t.225-3.3q0-5.25-4.8-5.25-3.15,0-5.55,14.1Q5.1-25.5,5.1-19.2,5.1-3,11.7-3q1.65,0,4.35-6Q19.65-16.8,19.65-20.85Z" transform="translate(541 540)"></path>
										<path className="el" id="l" d="M15.3,2.1Q7.5,2.1,3.15-9A49.627,49.627,0,0,1,0-26.85a252.21,252.21,0,0,1,2.7-33Q5.55-80.55,9.45-92.1q.75-10.2,5.1-10.2,9.9,0,9.9,15,0,12.15-4.8,29.25Q14.7-40.5,7.95-30.9a10.229,10.229,0,0,1-.675,1.05Q6.75-29.1,5.85-27.9a5.59,5.59,0,0,0-1.2,3.3q0,10.5,4.8,17.1Q12.75-3,15.6-3q5.55,0,14.1-29.7.3-1.5.825-3.6T31.8-41.25q.6-3.15,1.95-3.15t1.35,2.25q0,5.85-6,23.4Q22.2,2.1,15.3,2.1Zm2.1-98.85Q4.95-55.8,4.95-34.8q5.7-8.85,10.35-25.05,4.5-15,4.5-23.25Q19.8-94.35,17.4-96.75Z" transform="translate(578 540)"></path>
										<path className="el" id="a-2" data-name="a" d="M14.85-42q-.6-6.9-3.75-6.9-2.55,0-4.5,7.2Q6-40.05,5.25-37.35T3.45-31.2q-2.4,9.15-2.4,22.65A11.212,11.212,0,0,0,2.1-3.75Q3.45-.9,5.55-.9q2.85-1.65,6-11.55,1.2-3.9,2.1-7.275a41.771,41.771,0,0,0,1.2-6.075l1.8-13.8ZM24,0q-5.55,0-9.15-10.95Q10.95,3,4.8,3-4.05,3-3.6-18.9q.3-5.85,3.75-19.5Q4.5-54,7.5-54q6.6,0,8.4,3.45l2.7,5.4,5.25,1.8Q20.1-32.4,18.75-16.5a22.419,22.419,0,0,0,.6,7.2q1.2,5.25,3.75,5.25,5.1,0,13.95-36.9.9-3.45,2.1-3.45t1.2,2.25q0,5.55-5.55,22.65Q28.5,0,24,0Z" transform="translate(608 540)"></path>
										<path className="el" id="m" d="M67.65-42.6q0,8.25-9,31.65Q54.3.3,49.05.3q-6.6,0-7.65-12.6l-.15-4.05L40.8-28.5q-2.1,7.95-3.675,12.975T34.8-8.4Q31.35,0,27.75,0,22.5,0,22.5-8.85q0-2.4.225-5.775t.525-8.025q.3-4.65.525-8.025T24-36.45q0-1.35-1.05-1.35-1.65,0-3.9,10.5-1.65,8.1-2.775,12.9T14.7-7.95Q11.25,2.4,7.05,2.4,2.4,2.4,2.4-12.15v-3.9q0-2.4.15-5.4.15-3.15.225-5.475T2.85-30.9L1.5-34.35Q.75-44.7,4.95-44.7q3.75,0,3.75,5.85,0,1.95-.225,4.65T7.65-27.9q-.6,3.6-.825,6.3T6.6-16.95q0,3.45.225,6.675A44.55,44.55,0,0,0,7.65-4.2a28.99,28.99,0,0,0,4.2-10.35q1.2-6.9,2.1-11.25t1.35-6q3.75-13.95,8.25-13.95t4.5,10.05q-.75,6.45-1.125,11.55T26.55-15.3q0,7.2,1.5,7.2,3.15,0,8.1-18,4.8-16.95,4.05-19.65.3-1.65,3.15-1.65,1.95,0,5.85,2.4-3.75,16.95-3.75,29.25,0,11.4,3.6,11.4,4.05,0,8.85-13.95.75-1.65,5.85-20.4a16.832,16.832,0,0,0,.6-1.875q.3-1.125.6-2.625a1.564,1.564,0,0,1,1.5-1.2Q67.65-44.4,67.65-42.6Z" transform="translate(674 540)"></path>
										<path className="el" id="a-3" data-name="a" d="M14.85-42q-.6-6.9-3.75-6.9-2.55,0-4.5,7.2Q6-40.05,5.25-37.35T3.45-31.2q-2.4,9.15-2.4,22.65A11.212,11.212,0,0,0,2.1-3.75Q3.45-.9,5.55-.9q2.85-1.65,6-11.55,1.2-3.9,2.1-7.275a41.771,41.771,0,0,0,1.2-6.075l1.8-13.8ZM24,0q-5.55,0-9.15-10.95Q10.95,3,4.8,3-4.05,3-3.6-18.9q.3-5.85,3.75-19.5Q4.5-54,7.5-54q6.6,0,8.4,3.45l2.7,5.4,5.25,1.8Q20.1-32.4,18.75-16.5a22.419,22.419,0,0,0,.6,7.2q1.2,5.25,3.75,5.25,5.1,0,13.95-36.9.9-3.45,2.1-3.45t1.2,2.25q0,5.55-5.55,22.65Q28.5,0,24,0Z" transform="translate(737 540)"></path>
										<path className="el" id="g" d="M34.35,13.05q-13.05,0-13.05,1.05,0,.9.075,2.55t.075,3.45q0,1.8.075,3.45T21.6,26.1q0,14.7-3,21.9Q14.1,58.65,1.35,58.65A24.315,24.315,0,0,1-15.6,51.9a20.608,20.608,0,0,1-7.5-16.05q0-12.75,26.55-22.05A107.236,107.236,0,0,0,16.5,10.2q0-1.35-.15-3.375T15.9,1.95q-.45-5.4-.45-8.1Q14.85-6,12-1.05q-2.7,4.8-5.55,4.8-5.25,0-7.8-5.7A24.864,24.864,0,0,1-3.45-12.3q0-9.9,4.5-26.85Q5.7-56.85,9.9-56.85q3,0,8.25,6.15,5.7,6.6,9.3,7.65-3.75,6.9-5.85,19.2-.75,4.8-1.2,8.925A71.465,71.465,0,0,0,19.95-7.2q0,1.65.225,5.7T21,9.3q5.55-.6,9.45-.825t6-.225q6.6,0,6.6,3.9,0,2.55-3.3,2.55A12.878,12.878,0,0,1,34.35,13.05ZM19.2-40.2q0-9.75-7.95-9.75Q9.75-49.95,6-36,1.65-20.25,1.65-9.6,1.65,0,5.4,0q4.2,0,9.15-15.6Q19.2-30,19.2-40.2ZM17.1,14.7q-9.15,0-14.55,2.1A55.223,55.223,0,0,0-10.8,24.6q-7.8,6.3-7.8,12.6a15.186,15.186,0,0,0,5.85,12A18.6,18.6,0,0,0,0,54.15q17.7,0,17.7-27,0-.3-.15-3.375T17.1,14.7Z" transform="translate(772 540)"></path>
										<path className="el" id="l-2" data-name="l" d="M15.3,2.1Q7.5,2.1,3.15-9A49.627,49.627,0,0,1,0-26.85a252.21,252.21,0,0,1,2.7-33Q5.55-80.55,9.45-92.1q.75-10.2,5.1-10.2,9.9,0,9.9,15,0,12.15-4.8,29.25Q14.7-40.5,7.95-30.9a10.229,10.229,0,0,1-.675,1.05Q6.75-29.1,5.85-27.9a5.59,5.59,0,0,0-1.2,3.3q0,10.5,4.8,17.1Q12.75-3,15.6-3q5.55,0,14.1-29.7.3-1.5.825-3.6T31.8-41.25q.6-3.15,1.95-3.15t1.35,2.25q0,5.85-6,23.4Q22.2,2.1,15.3,2.1Zm2.1-98.85Q4.95-55.8,4.95-34.8q5.7-8.85,10.35-25.05,4.5-15,4.5-23.25Q19.8-94.35,17.4-96.75Z" transform="translate(807 540)"></path>
										<path className="el" id="i" d="M12.75-82.95q0,5.55-3.75,5.55a5.552,5.552,0,0,1-4.2-1.95A6.263,6.263,0,0,1,3-83.7a4.729,4.729,0,0,1,1.575-3.15A4.412,4.412,0,0,1,7.65-88.5Q12.75-88.5,12.75-82.95ZM25.05-42.3q0,3.75-5.25,20.85-3.9,12.3-4.95,14.7-3,6.6-7.2,6.6Q3-.15.75-5.55A27.233,27.233,0,0,1-.9-15q0-2.7.3-6.75t.9-9.6q.6-5.55.9-9.675t.3-6.825a4.059,4.059,0,0,1,3-1.05q3.9,0,3.9,2.1-.3,1.2-.825,3.375T6.3-37.95A116.715,116.715,0,0,0,3.45-14.7q0,10.05,4.5,10.05,2.1,0,5.1-7.5.15-.15,4.95-15.3.75-2.4,1.65-5.7T21.6-40.8q.9-3.6,2.1-3.6Q25.05-44.4,25.05-42.3Z" transform="translate(837 540)"></path>
										<path className="el" id="a-4" data-name="a" d="M14.85-42q-.6-6.9-3.75-6.9-2.55,0-4.5,7.2Q6-40.05,5.25-37.35T3.45-31.2q-2.4,9.15-2.4,22.65A11.212,11.212,0,0,0,2.1-3.75Q3.45-.9,5.55-.9q2.85-1.65,6-11.55,1.2-3.9,2.1-7.275a41.771,41.771,0,0,0,1.2-6.075l1.8-13.8ZM24,0q-5.55,0-9.15-10.95Q10.95,3,4.8,3-4.05,3-3.6-18.9q.3-5.85,3.75-19.5Q4.5-54,7.5-54q6.6,0,8.4,3.45l2.7,5.4,5.25,1.8Q20.1-32.4,18.75-16.5a22.419,22.419,0,0,0,.6,7.2q1.2,5.25,3.75,5.25,5.1,0,13.95-36.9.9-3.45,2.1-3.45t1.2,2.25q0,5.55-5.55,22.65Q28.5,0,24,0Z" transform="translate(857 540)"></path>
									</g>
								</svg>
							</div>

							<div className="star-drawing">
								<svg width="49" height="49.972" viewBox="0 0 49 49.972">
									<g fill="none" fillRule="evenodd" stroke="#ffffff" strokeWidth="1" id="stars" className="star" transform="translate(-897.598 -451.5)">
										<path className="el" d="M2027.5-1275.83l6.034,11.4,3.7-12.509.749.125c-.6,4.336-1.193,8.671-1.915,13.925l12.739-3.158.26.594-11.541,5.7,11.131,8.066-.409.686-11.211-5.848,4.974,12.951-.73.32-6.516-12.444c-1.805,6.381-3.307,11.693-4.81,17l-.418-.076c.888-5.935,1.775-11.871,2.769-18.512l-12.952,3.37q-.144-.291-.289-.581l11.793-5.834-11.022-7.838.423-.762,10.9,5.7-4.381-11.985.719-.29" transform="translate(-1121.464 1728.443)" />
										<path className="el" d="M3988.354-2298.01l-3.592,3.351-.252-.245,3.235-3.557h-6.7c0-.18,0-.361,0-.542h6.718l-4.625-4.848.2-.243,4.909,4.592v-6.691l.482,0v6.7l3.489-3.12.259.233-3.122,3.352h6.683c0,.184-.006.368-.008.552h-6.8l4.7,4.925-.239.246-4.841-4.594v6.6l-.506.018v-6.722" transform="translate(-3049.446 2773.468)" />
										<path className="el" d="M1243.756-1003.713c-.414-2.988-1.912-4.733-5.029-5.3,2.778-.65,4.646-2.073,4.967-5.176.6,2.8,2.007,4.672,5.033,5.1-2.953.677-4.62,2.326-4.972,5.372" transform="translate(-316.728 1505.184)" />
									</g>
								</svg>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12 text-center mb-5 mt-md-3">
						<h2>Web developer with 10+ years of experience</h2>
					</div>
				</div>

				{/* <div className="page-transition">
					<div className='ball'></div>
					<span>Click.me</span>
				</div> */}
			</header>

		)
	}
}

export default Header;