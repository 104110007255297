import '../App.scss';
import { HelmetProvider } from 'react-helmet-async';
import { AnimatedCursor } from 'react-animated-cursor-ts';


import SEO from './SEO';
import Header from './Header';
import Projects from '../projects/Projects';
import Footer from './Footer';

function App() {
	const helmetContext = {};

	return (
		<>
		<HelmetProvider context={helmetContext}>
			<SEO />

			<Header />

			<AnimatedCursor
				color='#5200FF'
				innerSize={8}
				outerSize={35}
				innerScale={1}
				outerScale={1.7}
				outerAlpha={0.7}
				outerStyle={{
					mixBlendMode: 'exclusion',
				}}
			/>


			<Projects />

			<Footer />
		</HelmetProvider>
		</>
	);
}

export default App;
