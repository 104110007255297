import React from 'react';
import ReactDOM from 'react-dom/client';


/* VENDORS */

// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
    duration: 1500,
})

/* END VENDORS */



import App from './backbone/App';
import reportWebVitals from './reportWebVitals';



const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

reportWebVitals();
