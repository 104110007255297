import { Helmet } from 'react-helmet-async';

function SEO() {
	return (
		
		<Helmet>
			<title>Paola Maglia | Web Developer & pop-corn lover!</title>
			<link rel="canonical" href="https://paolamaglia.it/" />

			<meta name="author" content="Paola Maglia" />

			<meta name="description" content="Hi! I'm Paola, web developer, WordPress developer, CSS3/Sass and animation lover from Bergamo, but now based in Cesenatico." />
			<meta property="og:title" content="Paola Maglia | Web Developer & pop-corn lover!" />
			<meta property="og:site_name" content="paolamaglia.it" />
			<meta property="og:url" content="https://paolamaglia.it/" />
			<meta property="og:description" content="Hi! I'm Paola, web developer, WordPress developer, CSS3/Sass and animation lover from Bergamo, but now based in Cesenatico." />
			<meta property="og:type" content="website" />
			<meta property="og:image" content="%PUBLIC_URL%media/images/ogimage.png" />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:creator" content="Paola Maglia" />
			<meta name="twitter:site" content="https://paolamaglia.it/" />
			<meta name="twitter:title" content="Paola Maglia | Web Developer & pop-corn lover!" />
			<meta name="twitter:description" content="Hi! I'm Paola, web developer, WordPress developer, CSS3/Sass and animation lover from Bergamo, but now based in Cesenatico." />
			<meta name="twitter:creator" content="Paola Maglia" />
			<meta property="twitter:image" content="%PUBLIC_URL%media/images/ogimage.png" /> 

			<meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
			<link rel="alternate" hrefLang="en" href="https://paolamaglia.it" />
			<link rel="alternate" hrefLang="x-default" href="https://paolamaglia.it" />
		</Helmet>
		
	)
}
export default SEO;